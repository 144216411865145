@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin: 0;
}
.ip-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
footer {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  background-image: url(./assets/pattern-bg-desktop.png);
  background-size: cover;
  background-repeat: no-repeat;
  font-size: 18px;
  color: #fff;
}
footer p a {
  color: #fff;
  font-weight: 600;
}
