.search {
    flex: 1;
    background-image: url(../assets/pattern-bg-desktop.png) ;
    height :30vh;
    width: 100%;
    padding: 40px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative
}
.search-from {
    display: flex ;
    flex-direction: column;
    justify-content: center ;
    align-items: center;
    margin-bottom: 50px;
    min-width: 65%;
}

.search h1{
    font-size:40px;
    color: #ffffff;
    padding-bottom: 1rem;
}
.search p{
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    color: rgb(126, 46, 46);
}
.search form{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.search form input{
    
    padding: 2rem ;
    width:  550px;
    border: none;
    border-radius: 20px 0 0 20px;
    font-size: 18px ;
    color: hsl(0, 0%, 17%);
    cursor: pointer;
    outline: none;
}
.search form input:hover {
    cursor: pointer
}

.search form input:disabled {
    cursor: not-allowed
}

.search form button{
    padding: 0px 2rem;
    border: none;
    border-radius: 0 20px 20px 0 ;
    cursor: pointer;
    outline: none;
    background-color: hsl(0, 0%, 17%)
}

.search form button:hover {
    background-color:hsl(0, 0%, 59%);
    transition: 0.5s;
    cursor: pointer;

}

/* Media query for 375px viewport width */
@media (max-width: 720px) {
    .search {
        height :40vh;
        
    }
    .search h1 {
        font-size: 30px; 
      }
      
    .search form input{
        padding: 1rem;
        width:  200px;
        

    }
    .search form button{
        padding: 0rem 1rem;
    }
    
  }